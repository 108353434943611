<template>
  <v-list-item
    :value="value"
    v-on="$listeners"
    v-bind="$attrs"
    style="height:96px;"
  >
    <template v-if="loading">
      <div>#{{ item.id }}</div>
      <v-spacer />
      <v-progress-circular indeterminate width="2" />
    </template>
    <template v-else>
      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
        <v-list-item-subtitle v-text="item.objectType.title" />
        <v-list-item-subtitle v-text="item.location" />
      </v-list-item-content>
      <v-list-item-avatar
        tile
        width="100"
        size="80"
        color="grey"
        class="rounded dynamic-saturation"
        v-if="item.media && item.media.length"
      >
        <v-img :src="getPicUrl(item.media[0])"></v-img>
      </v-list-item-avatar>
    </template>
  </v-list-item>
</template>
<script>
import opencity from "@/service/opencity";

export default {
  name: "OCObjectListItem",
  setup() {
    return {
      getPicUrl: opencity.getMediaPreviewUrl
    };
  },
  props: {
    item: {},
    navigateTo: {},
    value: {}
  },
  computed: {
    loading() {
      return !this.item.createdAt;
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(val) {
        const ac = new AbortController();
        if (val)
          opencity.getObject(this.item.id, { signal: ac.signal }).then(res => {
            this.item = res;
          });
        this.$once("hook:beforeDestroy", () => ac.abort());
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.v-list-item:focus {
  outline: 2px solid var(--v-primary-base);
}
</style>
