<template>
  <OCMapButton
      @click="geolocate"
      :color="geolocateColor"
  >
    <MglGeolocateControl
      :position-options="{ enableHighAccuracy: true }"
      :fit-bounds-options="{ maxZoom: 17 }"
      track-user-location
      position="bottom-right"
    />
    <v-icon v-text="geolocateIcon" />
  </OCMapButton>
</template>
<script>
import {MglGeolocateControl} from "vue-mapbox";
import OCMapButton from "@/components/map/OCMapButton";

export default {
  name: 'OCMapGeolocate',
  components: {OCMapButton, MglGeolocateControl},
  data() {
    return {
      geolocateState: "OFF"
    }
  },
  computed: {
    geolocateColor() {
      return this.geolocateState === "OFF" ? "" : "primary";
    },
    geolocateIcon() {
      switch (this.geolocateState) {
        case "BACKGROUND_ERROR":
        case "ACTIVE_ERROR":
          return "mdi-crosshairs-off";
        case "WAITING_ACTIVE":
          return "mdi-timer-sand";
        case "BACKGROUND":
          return "mdi-crosshairs";
        case "OFF":
        case "ACTIVE_LOCK":
        default:
          return "mdi-crosshairs-gps";
      }
    }
  },
  inject: ['map'],
  methods: {
    attachGeolocateControlState(geolocateControl) {
      if (!geolocateControl.flag) {
        geolocateControl.flag = true;
        const original = geolocateControl.trigger;
        const updateState = _ => this.updateGeolocateState();
        geolocateControl.trigger = function() {
          original.bind(geolocateControl)();
          updateState();
        };
        geolocateControl.on("geolocate", updateState);
        geolocateControl.on("error", updateState);
        geolocateControl.on("outofmaxbounds", updateState);
        geolocateControl.on("trackuserlocationstart", updateState);
        geolocateControl.on("trackuserlocationend", updateState);
      }
    },
    updateGeolocateState() {
      this.geolocateState = this.geolocateControl?._watchState ?? "OFF";
    },
    geolocate() {
      const map = this.map;
      const geolocateControl = (this.geolocateControl = map._controls.find(
        el => el.trigger
      ));
      this.attachGeolocateControlState(geolocateControl);
      this.geolocateControl.trigger();
    },
  }
}
</script>
