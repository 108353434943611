import opencity from "@/service/opencity";

export default {
  data: () => ({
    OCClassifier: opencity.getClassifier()
  }),
  created() {
    opencity.on("update:classifier", res => {
      this.OCClassifier = res;
    });
  }
};
