<template>
  <p class="data-value" v-if="!showAll">
    {{
      text.substring(0, stripLength) + (text.length > stripLength ? "..." : "")
    }}
    <a @click="showAll = true" v-if="text.length > stripLength">ещё</a>
  </p>
  <p class="data-value" v-else v-text="text" />
</template>
<script>
export default {
  name: "ExpandableParagraph",
  data: () => ({
    showAll: false,
  }),
  props: {
    stripLength: {
      default: 130,
      type: Number
    },
    text: String,
    value: { type: Function }
  }
}
</script>
<style lang="scss">

.v-label textarea {
  max-height: 260px;
  overflow-y: auto;
}

</style>