<template>
  <span>{{ text }}</span>
</template>
<script>
export default {
  name: 'TextPromise',
  props: {
    value: {},
  },
  data: () => ({
    text: null
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        let value = this.value;
        if (value instanceof Promise) {
          this.text = 'Загрузка...';
          value.then(res => {
            if (value !== this.value) return;
            this.text = res;
          });
        } else {
          this.text = this.value;
        }
      }
    }
  }
};
</script>
