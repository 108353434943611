<template>
  <div class="d-flex flex-column">
    <OCMapButton class="rounded-b-0 mb-0" @click="zoomIn">
      <v-icon>mdi-plus</v-icon>
    </OCMapButton>
    <OCMapButton class="rounded-t-0 mt-0" @click="zoomOut">
      <v-icon>mdi-minus</v-icon>
    </OCMapButton>
  </div>
</template>

<script>
import OCMapButton from "@/components/map/OCMapButton";

export default {
  name: 'OCMapZoom',
  components: {OCMapButton},
  inject: ['map'],
  methods: {
    zoomIn() {
      this.map.zoomIn({ duration: 500 });
    },
    zoomOut() {
      this.map.zoomOut({ duration: 500 });
    },
  }
}
</script>
