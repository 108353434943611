<template>
  <v-sheet
    light
    class="d-flex flex-column justify-center align-center rounded grey lighten-2 mb-3"
    :style="{ height: height + 'px' }"
  >
    <v-icon style="opacity:0.7" size="56">mdi-camera-off</v-icon>
    <span style="opacity:0.6" class="font-weight-medium text-body">Нет фото</span>
  </v-sheet>
</template>
<script>
export default {
  props: {
    height: {
      default: 220,
      type: Number
    }
  },
  name: 'NoPhoto'
}
</script>
<style lang="scss">

.v-label textarea {
  max-height: 260px;
  overflow-y: auto;
}

</style>