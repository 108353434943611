let months = [
  "Янв",
  "Фев",
  "Мар",
  "Апр",
  "Май",
  "Июн",
  "Июл",
  "Авг",
  "Сен",
  "Окт",
  "Ноя",
  "Дек"
];

function format(date) {
  let now = new Date();
  if (sameDay(date, now))
    return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");
  else if (sameYear(date, now))
    return date.getDate() + " " + months[date.getMonth()];
  else return date.toLocaleDateString();
}

function formatDate(date) {
  let now = new Date();
  if (sameDay(date, now))
    return "Сегодня";
  if (sameDay(date, new Date(now.getTime() - 24 * 60 * 60 * 1000)))
    return "Вчера";
  else if (sameYear(date, now))
    return date.getDate() + " " + months[date.getMonth()];
  else return date.toLocaleDateString();
}

export default function datetime(unix_timestamp) {
  if (!unix_timestamp) return "";
  let date = new Date(unix_timestamp * 1000);

  // return date.toISOString();

  // now.getUTCDate()

  return format(date);
}

export function datetime2(string) {
  let date = new Date(string);
  return format(date);
}

export function dateformat2(string) {
  let date = new Date(string);
  return formatDate(date);
}

export function fulldatetime(string) {
  let date = new Date(string);
  return date.toLocaleString();
}

function sameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

function sameYear(d1, d2) {
  return d1.getFullYear() === d2.getFullYear();
}
