import opencity from "@/service/opencity";
import {dateformat2} from "@/utils/datetime";

const config = [
  {
    type: "territory",
    key: "zone",
    label: "Территория",
    template: "$0"
  },
  {
    type: "collection",
    key: "list",
    label: "Входит в список",
    template: "В: $0"
  },
  {
    type: "select",
    key: "tag~responsible_team_text",
    label: "Обслуживающая организация",
    template: "Обслуживает: $0",
    values: ["МКУ «ГорЗеленСтрой»"]
  },
  {
    type: "dateRange",
    key: "created",
    label: "Дата создания",
    template: "Созданы $0"
  },
  {
    type: "dateRange",
    key: "updated",
    label: "Последнее обновление",
    template: "Обновлены $0"
  },
  {
    type: "user",
    key: "author",
    label: "Кто зарегистрировал",
    template: "От: $0"
  },
  {
    type: "select",
    key: "visibility",
    label: "Видимость",
    values: [
      { name: "Для всех", value: true },
      { name: "Для команды", value: false }
    ]
  }
];

const configType1 = [
  {
    type: "select",
    key: "tag~1,genus.name",
    label: "Род",
    template: "Род: $0",
    values: []
  },
  {
    type: "select",
    key: "tag~1,species",
    label: "Вид",
    template: "Вид: $0",
    values: []
  },
  {
    type: "select",
    key: "tag~1,condition",
    label: "Состояние",
    values: ["Хорошее", "Удовлетворительное", "Неудовлетворительное"]
  },
  {
    type: "range",
    key: "tag~1,height",
    label: "Высота (м)",
    template: "Высота $0 м"
  },
  {
    type: "range",
    key: "tag~1,trunk_diam",
    template: "Д. ствола $0 см",
    label: "Диаметр ствола (см)"
  },
  {
    type: "range",
    key: "tag~1,crown_diam",
    template: "Д. кроны $0 м",
    label: "Диаметр кроны (м)"
  },
  {
    type: "multiselect",
    key: "tag~1,damage",
    label: "Повреждения",
    values: []
  }
];

const configType14 = [
  {
    type: "select",
    key: "tag~14,genus.name",
    label: "Род",
    template: "Род: $0",
    values: []
  },
  {
    type: "select",
    key: "tag~14,species",
    label: "Вид",
    template: "Вид: $0",
    values: []
  },
  {
    type: "select",
    key: "tag~14,condition",
    label: "Состояние",
    values: ["Хорошее", "Удовлетворительное", "Неудовлетворительное"]
  },
  {
    type: "multiselect",
    key: "tag~14,damage",
    label: "Повреждения",
    values: []
  }
];

class FilterDefinition {
  constructor(defOptions) {
    this.definition = defOptions;
    this.key = defOptions?.key;
  }
  serialize(value) {
    return this.toApiQueryString(value);
  }
  applyToApiQueryObject(query, value) {
    console.warn("Default applyToApiQueryObject");
    query[this.definition.key] = value;
  }
  deserialize(string) {
    return null;
  }
  equals(value1, value2) {
    return this.serialize(value1) === this.serialize(value2);
  }
  copy(value) {
    return this.deserialize(this.serialize(value));
  }
  makeDescription(value) {
    const template = this.definition?.template ?? `${this.definition.label}: $0`;
    const result = text => template.replace("$0", text);

    const valueString = this.valueString(value);
    if (valueString instanceof Promise) {
      return valueString.then(result);
    }
    return result(valueString);
  }
  valueStringCache = {};
  valueString(value) {
    let cacheKey = this.serialize(value);
    let cached = this.valueStringCache[cacheKey];
    if (cached) {
      return cached;
    }
    const result = this.makeValueString(value);
    this.valueStringCache[cacheKey] = result;
    if (result instanceof Promise) {
      result.then(valueString => {
        this.valueStringCache[cacheKey] = valueString;
      });
    }
    return result;
  }
  makeValueString(value) {
    return value;
  }
  apply(options, value) {
    options[this.key] = value;
  }
  reset(options) {
    options[this.key] = undefined;
  }
  getValue(options) {
    return options[this.key];
  }
  isEmpty(value) {
    return value === undefined || value === null;
  }
}

class TerritoryFilter extends FilterDefinition {
  constructor(options) {
    super(options);
  }
  applyToApiQueryObject(query, value) {
    if (value.type === "region") {
      query.regions = value.id;
    } else if (value.type === "object") {
      query.parent = value.id;
    } else {
      console.warn("Unknown territory type: " + value.type, value);
    }
  }
  serialize(value) {
    return value.type + "," + value.id;
  }
  deserialize(string) {
    if (!string) return null;
    const [type, id] = string.split(",");
    return { type, id };
  }
  makeValueString(value) {
    if (value.type === "region") {
      return (
        value.name ??
        opencity
          .getRegions()
          .then(regions => regions.find(region => region.id == value.id).name)
      );
    }
    return (
      value.name ?? opencity.getObject(value.id).then(object => object.title)
    );
  }
}

class CollectionFilter extends FilterDefinition {
  constructor(options) {
    super(options);
  }
  applyToApiQueryObject(query, value) {
    return (query[this.def] = value);
  }
  serialize(value) {
    return value;
  }
  deserialize(string) {
    return string;
  }
  makeValueString(value) {
    return opencity
      .get("/lists")
      .then(res => res.json())
      .then(lists => lists.find(list => list.id === Number(value)).name);
  }
}

class DateRangeFilter extends FilterDefinition {
  constructor(options) {
    super(options);
  }
  applyToApiQueryObject(query, value) {
    if (value.from) query[this.definition.key + "From"] = value.from;
    if (value.to) query[this.definition.key + "To"] = value.to;
  }
  serialize(value) {
    return (value.from ?? "") + "," + (value.to ?? "");
  }
  deserialize(string) {
    if (!string) return { from: null, to: null };
    let [from, to] = string.split(",");
    from = from || null;
    to = to || null;
    return { from, to };
  }
  isEmpty(value) {
    return super.isEmpty(value) || (!value.from && !value.to);
  }
  reset(options) {
    options[this.key] = { from: null, to: null };
  }
  makeValueString(value) {
    const from = value.from ? dateformat2(value.from) : null;
    const to = value.to ? dateformat2(value.to) : null;
    if (from && to) return from + " - " + to;
    if (from) return "с " + from;
    if (to) return "до " + to;
    return "Любая";
  }
}

class UserFilter extends FilterDefinition {
  constructor(options) {
    super(options);
  }
  applyToApiQueryObject(query, value) {
    return (query[this.key] = value);
  }
  serialize(value) {
    return value;
  }
  deserialize(string) {
    if (!string) return null;
    return Number(string);
  }
  makeValueString(value) {
    if (this.isEmpty(value)) return "Любой";
    return opencity
      .getPerson(value)
      .then(person => person.fullName);
  }
}

class SelectFilter extends FilterDefinition {
  constructor(options) {
    super(options);
  }
  applyToApiQueryObject(query, value) {
    return (query[this.key] = value);
  }
  serialize(value) {
    return value;
  }
  deserialize(string) {
    if (!string) return null;
    let opt = this.definition.values.find(el => el.value?.toString && el.value.toString() === string);
    if (opt) return opt.value;
    return string;
  }
  makeValueString(value) {
    const item = this.definition.values.find(el => el.value === value);
    if(item) return item.name;
    return value;
  }
}

class MultiselectFilter extends FilterDefinition {
  //unlike selectfilter, works with arrays
  constructor(options) {
    super(options);
  }
  applyToApiQueryObject(query, value) {
    return (query[this.key] = "has~" + value.has);
  }
  serialize(value) {
    return value.has;
  }
  isEmpty(value) {
    return super.isEmpty(value) || !value.has;
  }
  reset(options) {
    options[this.key] = { has: null };
  }
  deserialize(string) {
    if (!string) return {has: null};
    return {has: string};
  }
  makeValueString(value) {
    // const item = this.definition.values.find(el => el.value === value);
    // if(item) return item.name;
    if (value.has === "empty") return "(Не заполнено)";
    if (value.has === "any") return "(Заполнено)";
    debugger
    let code = value.has.match(/^\d+/)?.[0];
    if (code) return code;
    return value.has ?? value;
  }
}

class RangeFilter extends FilterDefinition {
  constructor(options) {
    super(options);
  }
  applyToApiQueryObject(query, value) {
    if (this.isEmpty(value)) return;
    let array = [];
    if (value.from) array.push('gt~' + value.from);
    if (value.to) array.push('lt~' + value.to);
    query[this.key] = array.join(',');
  }
  serialize(value) {
    return (value.from ?? "") + "," + (value.to ?? "");
  }
  deserialize(string) {
    if (!string) return { from: null, to: null };
    let [from, to] = string.split(",");
    from = from || null;
    to = to || null;
    return { from, to };
  }
  isEmpty(value) {
    return super.isEmpty(value) || (!value.from && !value.to);
  }
  reset(options) {
    options[this.key] = { from: null, to: null };
  }
  makeValueString(value) {
    const from = value.from ? value.from : null;
    const to = value.to ? value.to : null;
    if (from && to) return from + " - " + to;
    if (from) return "от " + from;
    if (to) return "до " + to;
    return "Любая";
  }
}

const defs = {
  territory: TerritoryFilter,
  collection: CollectionFilter,
  dateRange: DateRangeFilter,
  user: UserFilter,
  select: SelectFilter,
  range: RangeFilter,
  multiselect: MultiselectFilter
};

//     <div class="col-md-4 col-12">
//         <v-label>Входит в список</v-label>
//         <div class="d-flex">
//             <OCListAutocomplete
//                 clearable
//                 hide-details
//                 placeholder="(Пусто)"
//                 v-model="optionsCopy.list"
//                 dense
//                 outlined
//                 filled
//             :background-color="background"
//             class="custom-field-border-behavior"
//             />
//         </div>
//     </div>
//     <div class="col-md-4 col-12">
//         <v-label for="filter-1">Обслуживающая организация</v-label>
//         <v-select
//             v-model="filters.responsible_team_text"
//             placeholder="Любая"
//             outlined
//             filled
//             dense
//         :background-color="background"
//         hide-details
//         clearable
//         class="custom-field-border-behavior"
//         :items="[
//         'МКУ «ГорЗеленСтрой»',
//         ]"
//         ></v-select>
// </div>
// <div class="col-md-4 col-12">
//     <v-label>Дата создания</v-label>
//     <div class="d-flex">
//         <OCDatePicker
//             input-class="rounded-r-0 custom-field-border-behavior"
//             v-model="optionsCopy.createdFrom"
//             placeholder="От"
//             dense
//             clearable
//             hide-details
//             filled
//             outlined
//         :background-color="background"
//         input-style="width: 50%"
//         />
//         <v-divider style="z-index:1; margin: 0" vertical />
//         <OCDatePicker
//             input-class="rounded-l-0 custom-field-border-behavior"
//             input-style="width: 50%;"
//             placeholder="До"
//             v-model="optionsCopy.createdTo"
//             dense
//             clearable
//             hide-details
//             filled
//             outlined
//         :background-color="background"
//         />
//     </div>
// </div>
// <div class="col-md-4 col-12">
//     <v-label>Последнее обновление</v-label>
//     <div class="d-flex">
//         <OCDatePicker
//             input-class="rounded-r-0 custom-field-border-behavior"
//             v-model="optionsCopy.updatedFrom"
//             placeholder="От"
//             dense
//             clearable
//             hide-details
//             filled
//             outlined
//         :background-color="background"
//         input-style="width: 50%"
//         />
//         <v-divider style="z-index:1; margin: 0" vertical />
//         <OCDatePicker
//             input-class="rounded-l-0 custom-field-border-behavior"
//             placeholder="До"
//             v-model="optionsCopy.updatedTo"
//             dense
//             clearable
//             hide-details
//             filled
//             outlined
//         :background-color="background"
//         input-style="width: 50%;"
//         />
//     </div>
// </div>
// <div class="col-md-4 col-12">
//     <v-label for="filter-1">Кто зарегистрировал</v-label>
//     <UserSelector
//         class="custom-field-border-behavior"
//         type="search"
//         hide-details
//         clearable
//         outlined
//         filled
//         dense
//     :background-color="background"
//     v-model="optionsCopy.author"
//     @change="updateFilters"
//     >
// </UserSelector>
// </div>
// <div class="col-md-2 col-6">
//     <v-label for="filter-1">Видимость</v-label>
//     <v-select
//         v-model="optionsCopy.visibility"
//         placeholder="Любая"
//         outlined
//         filled
//         dense
//     :background-color="background"
//     hide-details
//     clearable
//     item-text="name"
//     item-value="value"
//     class="custom-field-border-behavior"
//     :items="[
//     { name: 'Для всех', value: true },
//     { name: 'Для команды', value: false }
//     ]"
//     ></v-select>
// </div>
// </template>
// <template v-if="typeFilters === 1">
//     <div class="col-md-4 col-12">
//         <v-label for="filter-1">Род</v-label>
//         <v-autocomplete
//             type="search"
//             class="custom-field-border-behavior"
//             clearable
//             hide-details
//             id="filter-1"
//             v-model="filters['1,genus.name']"
//         :items="genera"
//         item-value="filter"
//         item-text="name"
//         :background-color="background"
//         placeholder="Все"
//         filled
//         outlined
//         dense
//         ></v-autocomplete>
// </div>
// <div class="col-md-4 col-12">
//     <v-label for="filter-2">Вид</v-label>
//     <v-autocomplete
//         type="search"
//         autocomplete="off"
//         class="custom-field-border-behavior"
//         autocorrect="off"
//         autocapitalize="off"
//         name="q"
//         role="combobox"
//         clearable
//         hide-details
//         id="filter-2"
//         v-model="filters['1,species']"
//     :items="species"
//     item-value="filter"
//     item-text="name"
//     :background-color="background"
//     placeholder="Все"
//     filled
//     outlined
//     dense
//     ></v-autocomplete>
// </div>
// <div class="col-md-4 col-12">
//     <v-label for="filter-3">Состояние</v-label>
//     <v-select
//         clearable
//         class="custom-field-border-behavior"
//         v-model="filters['1,condition']"
//         hide-details
//         item-value="filter"
//         item-text="name"
//         id="filter-3"
//     :items="conditions"
//     :background-color="background"
//     placeholder="Все"
//     filled
//     outlined
//     dense
//     ></v-select>
// </div>
// <div class="col-md-4 col-12">
//     <v-label>Высота (м)</v-label>
//     <div class="d-flex">
//         <VTextField
//             class="rounded-r-0 custom-field-border-behavior"
//             v-model.number="filters['1,height'].from"
//             placeholder="От"
//             dense
//             clearable
//             hide-details
//             filled
//             outlined
//         :background-color="background"
//         style="width: 50%"
//         />
//         <v-divider style="z-index:1; margin: 0" vertical />
//         <VTextField
//             class="rounded-l-0 custom-field-border-behavior"
//             style="width: 50%;"
//             placeholder="До"
//             v-model.number="filters['1,height'].to"
//             dense
//             clearable
//             hide-details
//             filled
//             outlined
//         :background-color="background"
//         />
//     </div>
// </div>
// <div class="col-md-4 col-12">
//     <v-label>Диаметр ствола (см)</v-label>
//     <div class="d-flex">
//         <VTextField
//             class="rounded-r-0 custom-field-border-behavior"
//             v-model.number="filters['1,trunk_diam'].from"
//             placeholder="От"
//             dense
//             clearable
//             hide-details
//             filled
//             outlined
//         :background-color="background"
//         style="width: 50%"
//         />
//         <v-divider style="z-index:1; margin: 0" vertical />
//         <VTextField
//             class="rounded-l-0 custom-field-border-behavior"
//             style="width: 50%;"
//             placeholder="До"
//             v-model.number="filters['1,trunk_diam'].to"
//             dense
//             clearable
//             hide-details
//             filled
//             outlined
//         :background-color="background"
//         />
//     </div>
// </div>
// <div class="col-md-4 col-12">
//     <v-label>Диаметр кроны (м)</v-label>
//     <div class="d-flex">
//         <VTextField
//             class="rounded-r-0 custom-field-border-behavior"
//             v-model.number="filters['1,crown_diam'].from"
//             placeholder="От"
//             dense
//             clearable
//             hide-details
//             filled
//             outlined
//         :background-color="background"
//         style="width: 50%"
//         />
//         <v-divider style="z-index:1; margin: 0" vertical />
//         <VTextField
//             class="rounded-l-0 custom-field-border-behavior"
//             style="width: 50%;"
//             placeholder="До"
//             v-model.number="filters['1,crown_diam'].to"
//             dense
//             clearable
//             hide-details
//             filled
//             outlined
//         :background-color="background"
//         />
//     </div>
// </div>
// <div class="col-md-4 col-12">
//     <v-label>Повреждения</v-label>
//     <div class="d-flex">
//         <VAutocomplete
//             class="custom-field-border-behavior"
//             v-model="filters.damage.has"
//             placeholder="Любые"
//         :items="damage"
//         item-text="name"
//         item-value="id"
//         dense
//         clearable
//         hide-details
//         filled
//         outlined
//         :background-color="background"
//         />
//     </div>
// </div>
// </template>
// <template v-if="typeFilters === 14">
//     <div class="col-md-4 col-12">
//         <v-label for="filter-1">Род</v-label>
//         <v-autocomplete
//             type="search"
//             class="custom-field-border-behavior"
//             clearable
//             hide-details
//             id="filter-1"
//             v-model="filters['14,genus.name']"
//         :items="generaBush"
//         item-value="filter"
//         item-text="name"
//         :background-color="background"
//         placeholder="Все"
//         filled
//         outlined
//         dense
//         ></v-autocomplete>
// </div>
// <div class="col-md-4 col-12">
//     <v-label for="filter-2">Вид</v-label>
//     <v-autocomplete
//         type="search"
//         autocomplete="off"
//         class="custom-field-border-behavior"
//         autocorrect="off"
//         autocapitalize="off"
//         name="q"
//         role="combobox"
//         clearable
//         hide-details
//         id="filter-2"
//         v-model="filters['14,species']"
//     :items="speciesBush"
//     item-value="filter"
//     item-text="name"
//     :background-color="background"
//     placeholder="Все"
//     filled
//     outlined
//     dense
//     ></v-autocomplete>
// </div>
// <div class="col-md-4 col-12">
//     <v-label for="filter-3">
//         Состояние
//     </v-label>
//     <v-select
//         clearable
//         class="custom-field-border-behavior"
//         v-model="filters['14,condition']"
//         hide-details
//         item-value="filter"
//         item-text="name"
//         id="filter-3"
//     :items="conditions"
//     :background-color="background"
//     placeholder="Все"
//     filled
//     outlined
//     dense
//     ></v-select>
// </div>
// <div class="col-md-4 col-12">
//     <v-label>Повреждения</v-label>
//     <div class="d-flex">
//         <VAutocomplete
//             class="custom-field-border-behavior"
//             v-model="filters.damage.has"
//             placeholder="Любые"
//         :items="damage"
//         item-text="name"
//         item-value="id"
//         dense
//         clearable
//         hide-details
//         filled
//         outlined
//         :background-color="background"
//         />
//     </div>
// </div>
// </template>
// <div style="position: sticky; bottom: 0; z-index: 1" class="col-12 d-flex align-center flex-wrap">
//     <v-alert
//         icon="mdi-information-outline"
//         v-if="!typeFilters && !options.advanced "
//         class="text-center text--secondary"
//     >
//         Больше фильтров доступно после выбора типа объектов
//     </v-alert>
//     <v-alert
//         icon="mdi-information-outline"
//         v-if="noFilters"
//         class="text-center text--secondary"
//     >
//         Фильтры отсутствуют
//     </v-alert>
//     <v-spacer />
//     <v-btn depressed class="mx-2" @click="$emit('update:options', null)">Сбросить</v-btn>
// <v-btn depressed color="primary" @click="updateFilters">Применить</v-btn>
// </div>
// </div>
// </template>
// <script>
//     import ZoneSelector from "@/components/ZoneSelector";
//     import genera, { species } from "@/assets/genera";
//     import OCDatePicker from "@/components/OCDatePicker";
//     import OCListAutocomplete from "@/components/OCListAutocomplete";
//     import damage from "@/views/Objects/NewObject/damage";
//     import UserSelector from "@/components/UserSelector.vue";
//     import generaBush, { species as speciesBush } from "@/assets/generaBush";
//
//     const conditions = ["Хорошее", "Удовлетворительное", "Неудовлетворительное"];
//
//     export default {
//     name: "OCFilterBlock",
//     components: {UserSelector, OCListAutocomplete, OCDatePicker, ZoneSelector },
//     props: {
//     background: {},
//     options: {},
//     type: {},
//     selectedRegion: {},
//     zone: {
//     default: false,
//     type: Boolean
// }
// },
//     data() {
//     return {
//     optionsCopy: {}
// };
// },
//     created() {
//     this.$watch(() => this.filters?.['1,species'], (val) => {
//     console.log("Species selected: " + val);
//     if (!val) return;
//
//     const searchList = this.typeFilters === 1 ? species : speciesBush;
//
//     this.filters["1,genus.name"] = searchList.find(
//     el => el.name === val
//     )?.parent?.name;
// });
// },
//     watch: {
//     options: {
//     handler(val) {
//     this.optionsCopy = val;
// },
//     immediate: true
// },
//     speciesBush(val) {
//     if (this.filters['14,species'] === "empty" || this.filters['14,species'] === "any") return;
//     if (!val?.find(el => el.name === this.filters['14,species'])) {
//     this.filters['14,species'] = null;
// }
// },
//     species(val) {
//     if (this.filters['1,species'] === "empty" || this.filters['1,species'] === "any") return;
//     if (!val?.find(el => el.name === this.filters['1,species'])) {
//     this.filters['1,species'] = null;
// }
// }
// },
//     computed: {
//     noFilters() {
//     if (this.type && this.type !== true && this.type !== "true") return ![1, 14].includes(this.typeFilters);
//     return false;
// },
//     damage() {
//     // console.log(damage)
//     return damage.reduce((o, el) => {
//     // console.log(el)
//     el.items.forEach(l => o.push({ name: l, group: el.group, id: l.substring(0, 3) }));
//     return o;
// }, []);
// },
//     filters() {
//     return this.optionsCopy.dataFilters;
// },
//     typeFilters() {
//     if (this.type && this.type !== true && this.type !== "true") return Number(this.type);
//     return Number(this.optionsCopy.type?.id || this.optionsCopy.type);
// },
//     conditions() {
//     const array = conditions.map(el => ({ name: el }));
//     return [
// { name: "(Не Заполнено)", filter: "empty" },
// { name: "(Заполнено)", filter: "any" },
//     ...array
//     ];
// },
//     generaBush: function () {
//     return generaBush.sort((a, b) => a.name.localeCompare?.(b.name));
// },
//     speciesBush() {
//     let speciesPre = (this.speciesPrefilteredBush ?? []).map(el => ({
//     name: el.name,
//     filter: el.name
// }));
//     return [
// { name: "(Не Заполнено)", filter: "empty" },
// { name: "(Заполнено)", filter: "any" },
//     ...speciesPre
//     ];
// },
//     genera: function() {
//     return genera.sort((a, b) => a.name.localeCompare?.(b.name));
// },
//     species() {
//     let speciesPre = (this.speciesPrefiltered ?? []).map(el => ({
//     name: el.name,
//     filter: el.name
// }));
//     return [
// { name: "(Не Заполнено)", filter: "empty" },
// { name: "(Заполнено)", filter: "any" },
//     ...speciesPre
//     ];
// },
//     speciesPrefilteredBush() {
//     const genus = this.filters["14,genus.name"];
//     if (!genus) return speciesBush;
//     return speciesBush.filter(el => el.parent.name === genus);
// },
//     speciesPrefiltered() {
//     const genus = this.filters["1,genus.name"];
//     if (!genus) return species;
//     return species.filter(el => el.parent.name === genus);
// }
// },
//     methods: {
//     updateFilters() {
//     this.$emit("update:options", this.optionsCopy);
// }
// }
// };
// </script>

export const defaultFilterDefinitions = [...config, ...configType1, ...configType14].reduce(
  (o, def) => (o[def.key] = new defs[def.type](def), o),
  {}
);
