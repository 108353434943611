<template>
  <v-menu
    ref="menu"
    v-model="menu"
    bottom
    :close-on-content-click="false"

  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        readonly
        :class="inputClass"
        :style="inputStyle"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      color="primary"
      :first-day-of-week="1"
      scrollable
      @input="menu = false"
    >
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: "OCDatePicker",
  props: {
    inputClass: {},
    inputStyle: {},
    value: {}
  },
  computed: {
    date: {
      get() {
        return this.innerValue;
      },
      set(val) {
        this.innerValue = val;
        this.$emit("input", val);
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.innerValue = val;
      }
    }
  },
  data: () => ({
    menu: false,
    innerValue: null
  }),
}
</script>