<template>
  <v-sheet v-if="!$vuetify.breakpoint.mobile" dark class="footer py-3 darken-1">
    <div class="c-container d-flex">
      <div>
        <div class="d-flex my-2 align-end">
          <img height="52" class="mr-3" src="@/assets/Coat_of_Arms_of_Perm.png">
          <div>
            <div class="logo-title">Зеленый город</div>
            <span>Пермь</span>
          </div>
        </div>
        <p class="body-2">Управление по экологии и природопользованию администрации г. Перми</p>
        <p class="body-2">Все материалы сайта доступны по лицензии CC Attribution 3.0 при условии ссылки на
          первоисточник
          (в случае использования материалов сайта в сети Интернет – интерактивная ссылка).</p>
      </div>
      <div class="">
        <div class="footer-buttons d-flex py-4">
          <router-link to="/about" text>О проекте</router-link>
          <router-link to="/news" text>Новости</router-link>
          <router-link to="/info" text>Информация</router-link>
          <router-link to="/about/contacts" text>Контакты</router-link>
        </div>
      </div>
    </div>
    <div class="c-container text-center pb-8 text-body-2">
      С вопросами и предложениями обращайтесь: 8 (342) 210-99-91  email: uep@perm.permkrai.ru
    </div>
  </v-sheet>
  <v-sheet v-else dark class="footer pa-3 darken-1">
      <div class="d-flex my-2 align-end justify-center">
        <img height="52" class="mr-3" src="@/assets/Coat_of_Arms_of_Perm.png">
        <div>
          <div class="logo-title">Зеленый город</div>
          <span>Пермь</span>
        </div>
      </div>
      <p class="body-2 text-center">Управление по экологии и природопользованию администрации г. Перми</p>
      <div class="footer-buttons d-flex pa-4 justify-center flex-wrap py-4">
        <router-link to="/about" text>О проекте</router-link>
        <router-link to="/news" text>Новости</router-link>
        <router-link to="/info" text>Информация</router-link>
        <router-link to="/about/contacts" text>Контакты</router-link>
      </div>
    <div class="c-container text-center text-body-2">
      С вопросами и предложениями обращайтесь: 8 (342) 210-99-91  email: uep@gorodperm.ru
    </div>
<!--      <div class="d-flex justify-center align-center" style="width: 100%">-->
<!--        <a href="https://play.google.com/store/apps/details?id=com.perm.bezbarierov"> <img src="@/assets/ru_badge.png"-->
<!--                                                                                           height="64"></a>-->
<!--        <a href="https://apps.apple.com/ru/app/id1555497518"> <img src="@/assets/App_Store.svg" height="42"></a>-->
<!--      </div>-->
    <p class="body-2 text-center">Все материалы сайта доступны по лицензии CC Attribution 3.0 при условии ссылки на
      первоисточник
      (в случае использования материалов сайта в сети Интернет – интерактивная ссылка).
    </p>

  </v-sheet>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>
<style lang="scss">
.footer {
  background-color: #7a9c49 !important;
}

.footer-buttons {
  margin: 0 -8px;

  a {
    color: white !important;
    white-space: nowrap;
    margin: 4px 8px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.logo-title {
  font-weight: bold;
  font-size: 1.25rem;
}
</style>
