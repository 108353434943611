const oopt_schema = {
  fields: [
    {
      key: "areaBig",
      name: "Площадь (га)",
      type: "number"
    },
    {
      key: "ooptstatus",
      name: "Статус",
      type: "option",
      options: ["Рег.", "Местный"]
    },
    {
      key: "condition",
      name: "Состояние",
      type: "option",
      options: ["Хорошее", "Среднее", "Плохое"]
    }
  ]
};

const tropa_schema = {
  fields: [
    {
      key: "length",
      name: "Протяженность маршрута (м)",
      type: "number"
    },
    {
      key: "season",
      name: "Сезонность",
      type: "option",
      options: ["С мая по октябрь", "Всесезонная"]
    },
    {
      key: "year_created",
      name: "Год создания",
      type: "number"
    },
    {
      key: "owner",
      name: "Кем содержится",
      type: "string"
    }
  ]
};

const categories = [
  {
    name: "yard",
    icon: {
      svg: true,
      value: `<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="128" x2="128" y1="232" y2="88"/><line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="128" x2="80" y1="152" y2="128"/><line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="128" x2="176" y1="128" y2="104"/><path d="M132.5,181.2A64,64,0,1,0,194.7,69.9a8.6,8.6,0,0,1-4-4.2,68,68,0,0,0-125.4,0,8.6,8.6,0,0,1-4,4.2,64,64,0,1,0,62.2,111.3A8.2,8.2,0,0,1,132.5,181.2Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/></svg>`
    },
    title: "Зеленые насаждения",
    short_desc:
      "Данные по деревьям, кустарникам и цветникам на общественных пространствах",
    id: 1,
    topics: [
      {
        id: 1,
        title: "Деревья",
        disabled: false,
        geometry: "Point",
        filters: [
          {
            key: "species",
            display_type: "wiki"
          }
        ],
        schema: {
          fields: [
            {
              key: "genus",
              name: "Род",
              type: "genera",
              taxonomy: "trees",
              species: "species",
              constraints: {
                required: true
              }
            },
            {
              key: "species",
              name: "Вид",
              type: "species",
              taxonomy: "trees",
              genera: "genera",
              constraints: {}
            },
            {
              key: "trunk_diam",
              type: "number",
              name: "Диаметр ствола (см)",
              constraints: {
                minimum: 0,
                maximum: 1000,
                required: true
              }
            },
            {
              key: "crown_diam",
              type: "number",
              name: "Диаметр кроны (м)",
              constraints: {
                minimum: 0,
                maximum: 100
              }
            },
            {
              key: "height",
              type: "number",
              name: "Высота (м)",
              constraints: {
                minimum: 0,
                maximum: 100
              }
            },
            {
              key: "trunk_count",
              type: "number",
              name: "Количество стволов",
              constraints: {
                minimum: 0,
                maximum: 100
              }
            },
            {
              key: "plant_year",
              type: "number",
              name: "Год посадки",
              constraints: {
                minimum: 1000
              }
            },
            {
              key: "age",
              name: "Класс возраста",
              type: "option",
              options: [
                "1 - Молодое",
                "2 - Средневозрастное",
                "3 - Приспевающее",
                "4 - Спелое",
                "5 - Перестойное"
              ]
            },
            {
              key: "condition",
              name: "Состояние дерева",
              type: "option",
              options: ["Хорошее", "Удовлетворительное", "Неудовлетворительное"]
            },
            {
              key: "damage",
              name: "Повреждения",
              type: "multiple_option",
              taxonomy: "damage"
            }
          ]
        }
      },
      {
        id: 12,
        title: "Группы деревьев",
        disabled: false,
        geometry: "Polygon",
        active: true,
        schema: {
          fields: [
            {
              key: "area",
              name: "Площадь (м²)",
              type: "area",
              constraints: {
                required: true,
                auto: true,
                force_auto: true
              }
            },
            {
              key: "group_contents",
              name: "Формула древостоя",
              type: "string",
              constraints: {
                required: true
              }
            },
            {
              key: "condition",
              type: "option",
              name: "Состояние",
              options: ["Хорошее", "Удовлетворительное", "Неудовлетворительное"]
            }
          ]
        }
      },
      {
        id: 14,
        title: "Кустарники",
        disabled: false,
        geometry: "Point",
        active: true,
        schema: {
          fields: [
            {
              key: "genus",
              name: "Род",
              type: "genera",
              taxonomy: "bushes",
              species: "species"
            },
            {
              key: "species",
              name: "Вид",
              type: "species",
              taxonomy: "bushes",
              genera: "genera"
            },
            {
              key: "height",
              type: "number",
              name: "Высота (м)",
              constraints: {
                minimum: 0
              }
            },
            {
              key: "plant_year",
              type: "number",
              name: "Год посадки"
            },
            {
              key: "age",
              name: "Класс возраста",
              type: "option",
              options: [
                "1 - Молодое",
                "2 - Средневозрастное",
                "3 - Приспевающее",
                "4 - Спелое",
                "5 - Перестойное"
              ]
            },
            {
              key: "condition",
              name: "Состояние кустарника",
              type: "option",
              options: ["Хорошее", "Удовлетворительное", "Неудовлетворительное"]
            },
            {
              key: "damage",
              name: "Повреждения",
              type: "multiple_option",
              taxonomy: "damage"
            }
          ]
        }
      },
      {
        id: 51,
        title: "Группы кустарников",
        disabled: false,
        geometry: "Polygon",
        active: true,
        default_title: {
          field: "biogroup_name"
        },
        schema: {
          fields: [
            {
              key: "biogroup_name",
              name: "Название биогруппы",
              type: "string",
              constraints: {
                required: true
              }
            },
            {
              name: "Площадь (м²)",
              type: "area",
              key: "area",
              constraints: {
                required: true,
                auto: true,
                force_auto: true
              }
            },
            {
              key: "bush_count",
              name: "Количество кустов в посадке",
              type: "number",
              constraints: {
                minimum: 1
              }
            },
            {
              key: "age",
              name: "Класс возраста",
              type: "option",
              options: [
                "1 - Молодое",
                "2 - Средневозрастное",
                "3 - Приспевающее",
                "4 - Спелое",
                "5 - Перестойное"
              ]
            },
            {
              key: "avg_height",
              name: "Cредняя высота кустарниковой посадки (м)",
              type: "number",
              constraints: {
                minimum: 0
              }
            },
            {
              key: "health",
              name: "Состояние",
              type: "option",
              options: [
                "1 - Хорошее",
                "2 - Удовлетворительное",
                "3 - Неудовлетворительное"
              ]
            }
          ]
        }
      },
      {
        id: 15,
        title: "Цветники, клумбы",
        disabled: true
      },
      {
        id: 16,
        title: "Газоны",
        disabled: true
      }
    ],
    disabled: false
  },
  {
    name: "transport2",
    icon: {
      svg: true,
      value: `<?xml version="1.0" ?><svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><title/><g data-name="Layer 5" id="Layer_5"><path d="M62,57a1,1,0,0,0,1-1V52a1,1,0,0,0-1-1H59V49h1a1,1,0,0,0,1-1V40a1,1,0,0,0-1-1H51V31h3a1,1,0,0,0,.71-.29l1-1a3,3,0,1,0-1.41-1.41l-.71.71H51V25h1a1,1,0,0,0,1-.68l2-6a1,1,0,0,0-.14-.9l0,0h0l-4-5a1,1,0,0,0-1.56,0l-4,5h0l0,0a1,1,0,0,0-.14.9l2,6A1,1,0,0,0,48,25h1v4H46.41l-.71-.71a3,3,0,1,0-1.41,1.41l1,1A1,1,0,0,0,46,31h3v8H38.12A17.61,17.61,0,0,0,40,31c0-8.82-6.28-16-14-16a13,13,0,0,0-9.4,4.17L13,1.8a1,1,0,0,0-2,0L1,49.8a1,1,0,0,0,.2.83A1,1,0,0,0,2,51h9V61H1v2H63V61H61V57ZM57,26a1,1,0,1,1-1,1A1,1,0,0,1,57,26ZM50,14.6,51.92,17H48.08ZM47.39,19h5.22l-1.33,4H48.72ZM42,27a1,1,0,1,1,1,1A1,1,0,0,1,42,27ZM61,55H31V53H61ZM27,47a12.71,12.71,0,0,0,4-1v2a1,1,0,0,0,1,1h1v2H30a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h1v4H27Zm24,4V49h2v2Zm-2,0H39V49H49ZM37,51H35V49h2Zm-4,6h2v4H33Zm4,0H49v4H37Zm14,0h4v4H51Zm6-6H55V49h2Zm2-4H33V41H59ZM26,17c6.62,0,12,6.28,12,14a15.4,15.4,0,0,1-2.17,8H32a1,1,0,0,0-1,1v3.71a10.73,10.73,0,0,1-4,1.22V35.41l3.71-3.7-1.42-1.42L27,32.59V29H25v8.59l-1.29-1.3-1.42,1.42L25,40.41v4.53a10.53,10.53,0,0,1-3.21-.85L17.11,21.63A11.28,11.28,0,0,1,26,17ZM3.23,49,12,6.9,20.77,49H13V37.41l2.71-2.7-1.42-1.42L13,34.59V30H11V40.59l-2.29-2.3L7.29,39.71,11,43.41V49ZM13,51h9a1,1,0,0,0,.78-.37,1,1,0,0,0,.2-.83l-.71-3.39a12.1,12.1,0,0,0,2.73.53V61H13ZM59,61H57V57h2Z"/></g></svg>`
    },
    title: "Территории общего пользования",
    id: 2,
    topics: [
      {
        id: 2,
        title: "Парки, скверы",
        container: true,
        active: true
      },
      {
        id: 3,
        title: "Сады",
        container: true,
        active: true
      },
      {
        id: 4,
        title: "Бульвары",
        container: true,
        active: true
      },
      {
        id: 17,
        title: "Аллеи"
      },
      {
        id: 19,
        title: "Набережные"
      }
    ],
    short_desc: "Все парки, скверы, сады и аллеи города",
    disabled: true
  },
  {
    name: "transport",
    icon: {
      svg: true,
      value: `<?xml version="1.0" ?><svg viewBox="40 40 420 420" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><g id="ECO_shield"><path d="M400.1343,108.0792,325.02,94.8927a398.02,398.02,0,0,0-138.04,0l-75.1143,13.1865a10,10,0,0,0,3.458,19.6992l3.2666-.5734V257.2081a128.2209,128.2209,0,0,0,46.857,99.3q.1405.1158.2856.2266l84.1948,64.3481a10,10,0,0,0,12.1446,0l84.1948-64.3481q.145-.1106.2856-.2266a128.2209,128.2209,0,0,0,46.857-99.3V127.205l3.2666.5734a10,10,0,0,0,3.458-19.6992ZM304.2715,287.25A53.6717,53.6717,0,0,1,266,315.4191V300.4032l44.3376-45.5A54.1973,54.1973,0,0,1,304.2715,287.25ZM266,271.7456V251.1281l28.8721-29.4456,6.3217,9.3108c.627.9233,1.2173,1.8606,1.783,2.8063Zm0-49.1842V179.1591l17.4119,25.6445Zm107.41,34.6467a108.2865,108.2865,0,0,1-39.4356,83.7491L266,392.9081V335.6617a74.66,74.66,0,0,0,51.7407-115.9028l-53.4673-78.7475a10,10,0,0,0-16.5468,0l-53.4673,78.7475a73.467,73.467,0,0,0-10.8955,59.2276,75.2315,75.2315,0,0,0,36.9326,48.3623,10,10,0,1,0,9.5625-17.5655,55.1971,55.1971,0,0,1-27.0581-35.51,53.6934,53.6934,0,0,1,8.0054-43.28L246,179.1591v213.749l-67.9741-51.9509A108.2865,108.2865,0,0,1,138.59,257.2081V123.694l51.8477-9.1021a378.0535,378.0535,0,0,1,131.124,0L373.41,123.694Z"/></g></svg>`
    },
    title: "Природоохранные зоны, ООПТ",
    id: 3,
    topics: [
      {
        id: 31,
        title: "Историко-природные комплексы",
        active: true,
        schema: oopt_schema
      },
      {
        id: 32,
        title: "Охраняемые ландшафты",
        active: true,
        schema: oopt_schema
      },
      {
        id: 33,
        title: "Ботанические памятники природы",
        active: true,
        schema: oopt_schema
      },
      {
        id: 34,
        title: "Ботанические природные резерваты",
        active: true,
        schema: oopt_schema
      },
      {
        id: 35,
        title: "Природные культурно-мемориальные парки",
        active: true,
        schema: oopt_schema
      },
      {
        id: 36,
        title: "Экологические парки",
        active: true,
        schema: oopt_schema
      }
    ],
    short_desc: "Территории Перми с различными режимами охраны природы",
    disabled: true
  },
  {
    name: "transport",
    id: 4,
    icon: {
      svg: true,
      value: `<?xml version="1.0" ?><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><defs><style>.a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;}</style></defs><title/><path class="a" d="M8.908,6.058a.942.942,0,1,0,1.249-1.409l-2.929-3.1a1,1,0,0,0-1.456,0L2.847,4.66a.942.942,0,1,0,1.262,1.4L1.449,9.265A.942.942,0,1,0,2.8,10.58L.778,13.161a.942.942,0,0,0,.664,1.609l10.116,0a.944.944,0,0,0,.663-1.612L10.165,10.6a.942.942,0,0,0,1.325-1.34Z"/><path class="a" d="M22.328,12.764a.928.928,0,0,0,.418-1.523l-2.2-3.03c.793.862,1.932-.557,1.064-1.333L19.3,3.834a1,1,0,0,0-1.6.007L15.437,6.888c-.862.775.284,2.2,1.077,1.323l-2.268,3.038a.924.924,0,0,0,.468,1.523Z"/><line class="a" x1="6.5" x2="6.5" y1="14.772" y2="18.772"/><line class="a" x1="18.5" x2="18.5" y1="12.772" y2="15.772"/><path class="a" d="M.5,19.246a17.026,17.026,0,0,1,14.95,3.523"/><path class="a" d="M11.571,18.037A15.035,15.035,0,0,1,23.5,16.312"/></svg>`
    },
    topics: [
      { id: 41, title: "Лесничества" },
      { id: 42, title: "Зоны активного отдыха" },
      { id: 43, title: "Прогулочные зоны" }
    ],
    title: "Городские леса",
    short_desc: "Более 40% территории Перми занимают городские леса",
    disabled: true
  },
  {
    name: "transport",
    id: 5,
    icon: {
      svg: true,
      value: `<?xml version="1.0" ?><svg viewBox="10 10 80 80" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><title/><g data-name="Calque 2" id="Calque_2"><path d="M85.08,47.56H74.25a1.5,1.5,0,0,0-1.5,1.5v4.46H67.07l-5.83-12h8.92a1.5,1.5,0,0,0,1.5-1.5v-6a1.5,1.5,0,0,0-1.5-1.5H30.54a1.5,1.5,0,0,0-1.5,1.5v6a1.5,1.5,0,0,0,1.5,1.5h7.7l-5.2,12H28V49.06a1.5,1.5,0,0,0-1.5-1.5H15.62a1.5,1.5,0,0,0-1.5,1.5v6a1.5,1.5,0,0,0,1.5,1.5H31.74l-4.32,9.92a1.51,1.51,0,0,0,.78,2,1.61,1.61,0,0,0,.6.12,1.49,1.49,0,0,0,1.37-.9L35,56.52H65.2l5.35,11a1.5,1.5,0,0,0,1.35.84,1.55,1.55,0,0,0,.66-.15,1.51,1.51,0,0,0,.69-2l-4.71-9.64H85.08a1.5,1.5,0,0,0,1.5-1.5v-6A1.5,1.5,0,0,0,85.08,47.56Zm-1.5,6H75.75v-3h7.83ZM32,35.61H68.66v3H32Zm9.44,6s0,0,0-.08H58a1.27,1.27,0,0,0,.12.36l5.66,11.6H36.31ZM17.12,53.52v-3H25v3H17.12Z"/></g></svg>`
    },
    title: "Инфраструктура",
    topics: [
      { id: 6, title: "Экотропы", active: true, schema: tropa_schema },
      { id: 46, title: "Детские площадки" },
      { id: 47, title: "Спортивные площадки" },
      { id: 48, title: "Визит-центры" },
      { id: 49, title: "Информационные стенды" },
      { id: 50, title: "Места для выгула собак" },
      { id: 51, title: "Пикниковые места отдыха" },
      { id: 52, title: "Места обитания уток" }
    ],
    short_desc:
      "Экотропы, информационные стенды и площадки для активного отдыха",
    disabled: true
  },
  {
    name: "transport",
    id: 6,
    icon: {
      svg: true,
      value: `<?xml version="1.0" ?><svg viewBox="0 0 22.627 22.627" xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="currentColor" stroke-width="0.3px"><g data-name="Layer 2" id="Layer_2"><g data-name="Layer 1" id="Layer_1-2"><path d="M22.55,13.811a3.525,3.525,0,0,0-2.767-2.767c-1.444-.372-2.309.516-3.561.821V6.748a.344.344,0,0,0-.343-.343H10.762c.3-1.251,1.194-2.12.821-3.561A3.525,3.525,0,0,0,8.816.077,3.566,3.566,0,0,0,4.569,3.55c-.094,1.058.713,1.881.889,2.855H.343A.344.344,0,0,0,0,6.748V19.314a.343.343,0,0,0,.686,0,8.577,8.577,0,0,1,8.567-8.568H11.88v2.628a8.577,8.577,0,0,1-8.567,8.567.343.343,0,0,0,0,.686H15.879a.344.344,0,0,0,.342-.343V17.169c.975.176,1.8.982,2.855.888A3.564,3.564,0,0,0,22.55,13.811Zm-1.269,2.521a2.866,2.866,0,0,1-3.684.626,3.312,3.312,0,0,0-1.718-.5.344.344,0,0,0-.343.343v5.14H6.809a9.268,9.268,0,0,0,5.757-8.567V10.4a.342.342,0,0,0-.342-.342H9.253A9.266,9.266,0,0,0,.686,15.819V7.091H5.827a.343.343,0,0,0,.342-.343C6.257,5.606,5.2,4.7,5.255,3.55a2.856,2.856,0,1,1,5.285,1.5,3.236,3.236,0,0,0-.487,1.7.343.343,0,0,0,.343.343h5.14v5.14a.343.343,0,0,0,.343.343c1.352.054,2.366-1.251,3.773-.857A2.874,2.874,0,0,1,21.281,16.332ZM3.912,19.2,1.957,21.155a.343.343,0,0,1-.485-.484l1.955-1.956V16.481a.343.343,0,0,1,.686,0V18.03L5.942,16.2V13.968a.342.342,0,0,1,.684,0v1.548l2.385-2.384a.343.343,0,0,1,.485.485L7.111,16H8.66a.343.343,0,0,1,0,.685H6.426L4.6,18.515h1.55a.343.343,0,0,1,0,.685Z" data-name="environmental solutions" id="environmental_solutions"/></g></g></svg>`
    },
    title: "Другие озелененные территории",
    topics: [
      { id: 53, title: "Территории ограниченного пользования" },
      { id: 54, title: "Территории специального назначения" },
      { id: 55, title: "Природно-рекреационные территории" },
      { id: 56, title: "Места массового отдыха у воды" }
    ],
    short_desc: "Речные долины и другие участки с ограниченным использованием",
    disabled: true
  }
];

export default categories;
