<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    content-class="dialog"
  >
    <v-sheet tile>
      <div class="d-flex">
        <v-spacer />
        <v-btn class="ma-4 mb-0" icon @click="$emit('input', false)">
          <v-icon size="24">$close</v-icon>
        </v-btn>
      </div>
      <div class="px-4 px-md-10 pb-12">
        <div class="text-h5 text-center font-weight-bold">
          Зеленые насаждения
        </div>
        <div class="mt-8">
          Leo, sed leo lectus viverra purus. Odio suspendisse in sed pretium
          platea nec. A ornare mi volutpat lacinia egestas. Aliquam vestibulum
          elementum volutpat habitant ultricies lectus in suspendisse massa.
          Maecenas urna tellus hendrerit dignissim consectetur id.
        </div>
        <div class="mt-8 mb-4 text-center">
          <a>Подробнее</a>
        </div>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    }
  }
};
</script>

<style></style>
