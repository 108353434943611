<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    content-class="dialog"
  >
    <v-sheet tile>
      <div class="d-flex">
        <v-spacer />
        <v-btn class="ma-4 mb-0" icon @click="$emit('input', false)">
          <v-icon size="24">$close</v-icon>
        </v-btn>
      </div>
      <div class="px-4 px-md-10 pb-12">
        <div class="text-h5 text-center font-weight-bold">
          Вы уверены, что хотите отменить создание объекта?
        </div>
        <div
          class="
            warning-block
            text-body-2
            px-4
            py-3
            px-md-8
            py-md-6
            mt-8
            d-flex
            align-center
          "
        >
          <v-icon color="warning" class="mr-4 flex-shrink-0" size="32"
            >mdi-alert</v-icon
          >
          Если вы не сохраните процесс создания сообщения, все данные будут
          утрачены.
        </div>
        <div
          class="mt-8 mb-4 d-flex justify-md-space-around justify-space-between"
        >
          <TextButton @click="$emit('exit')" class="mr-">
            <v-icon left size="24">$delete</v-icon>
            Удалить
          </TextButton>
          <TextButton @click="$emit('exit')" class="ml-">
            <v-icon left size="24">mdi-content-save-outline</v-icon>
            Отмена
          </TextButton>
        </div>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import TextButton from "./TextButton.vue";

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TextButton
  }
};
</script>

<style></style>
