const osm = {
  version: 8,
  sources: {
    "raster-tiles": {
      type: "raster",
      tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
      tileSize: 256,
      attribution:
        '© <a target="_top" rel="noopener" href="https://osm.org/copyright">OpenStreetMap</a> Contributors'
    }
  },
  layers: [
    {
      id: "simple-tiles",
      type: "raster",
      source: "raster-tiles",
      minzoom: 0,
      maxzoom: 22
    }
  ]
};

// const style1 = `mapbox://styles/oplayer/cl5wcgzgn000o14ogfejlkzp9`;

const style2 = `mapbox://styles/oplayer/cl5wcnai6002614p26iolbdxr`;

const imagery = `mapbox://styles/oplayer/cl5wcgzgn000o14ogfejlkzp9`;

export default {
  osm,
  imagery,
  schematic: style2,
  empty: {version: 8, sources: {}, layers: []}
};
