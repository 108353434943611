<template>
  <v-btn
    v-bind="$attrs"
    text
    class="text-none opacity-text-button font-"
    :style="{ opacity }"
    :ripple="false"
    v-on="$listeners"
    small
    ><slot
  /></v-btn>
</template>

<script>
export default {
  props: {
    opacity: {
      default: 0.7,
      type: Number
    }
  }
};
</script>

<style lang="scss">
.v-btn.opacity-text-button {
  line-height: 24px;
  font-size: 16px !important;
  letter-spacing: 0;
  font-weight: 500;
  opacity: 0.7;
  &:before {
    opacity: 0 !important;
  }
  &:hover {
    opacity: 1 !important;
  }
}
</style>
