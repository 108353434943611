export default [
  {
    group: "Базовые",
    items: [
      "151  Преждевременный листопад",
      "152  Опадение побегов",
      "153  Водяные побеги",
      "154  Сухобочины, подсушены",
      "155  Вторичная крона",
      "156  Поросль от корней и пней"
    ]
  },

  {
    group: "Механические повреждения",
    items: [
      "201  Корней свежее (<3/4 корневых лап)",
      "202  Корней свежее (>3/4 корневых лап)",
      "203  Корней прошлых лет (<3/4 корневых лап)",
      "204  Корней прошлых лет (>3/4 корневых лап)",
      "205  Обрыв корней (вывал) свежий",
      "206  Обрыв корней (вывал) прошлых лет",
      "207  Ствола в области кроны свежее (<2/3 окружности ствола)",
      "208  Ствола в области кроны свежее (>2/3 окружности ствола)",
      "209  Ствола в области кроны прошлых лет (<2/3 окружности ствола)",
      "210  Ствола в области кроны прошлых лет (>2/3 окружности ствола)",
      "211  Ствола под кроной свежее (<2/3 окружности ствола)",
      "212  Ствола под кроной свежее (>2/3 окружности ствола)",
      "213  Ствола под кроной прошлых лет (<2/3 окружности ствола)",
      "214  Ствола под кроной прошлых лет (>2/3 окружности ствола)",
      "215  Слом ствола в области кроны свежий",
      "216  Слом ствола в области кроны прошлых лет",
      "217  Слом ствола под кроной свежий",
      "218  Слом ствола под кроной прошлых лет",
      "219  Трещина ствола <1/3 диаметра",
      "220  Трещина ствола >1/3 диаметра",
      "221  Расщепление ствола в верхней части",
      "222  Расщепление ствола у комля",
      "223  Ветвей (<3/4) свежее",
      "224  Ветвей (>3/4) свежее",
      "225  Ветвей (<3/4 ) прошлых лет",
      "226  Ветвей (>3/4) прошлых лет",
      "227  Листвы, хвои (град, охлёст, обдирание, и т.п.)"
    ]
  },

  {
    group: "Деформация",
    items: ["250  Листьев, хвои", "251  Побегов", "252  Стволов"]
  },

  {
    group: "Повреждение огнем",
    items: [
      "301  Ожог корней свежий (<3/4 корневых лап)",
      "302  Ожог корней свежий (>3/4 корневых лап)",
      "303  Ожог корней 1-3 летней давности (<3/4 корневых лап)",
      "304  Ожог корней 1-3 летней давности (>3/4 корневых лап)",
      "305  Ожог корней прошлых лет (<3/4 корневых лап)",
      "306  Ожог корней прошлых лет (>3/4 корневых лап)",
      "311  Ожог корневой шейки свежий (<3/4 окружности)",
      "312  Ожог корневой шейки свежий (>3/4 окружности)",
      "313  Ожог корневой шейки 1-3 лет (<3/4 окружности)",
      "314  Ожог корневой шейки 1-3 лет (>3/4 окружности)",
      "315  Ожог корневой шейки пр. лет (<3/4 окружности)",
      "316  Ожог корневой шейки пр. лет (>3/4 окружности)",
      "321  Ожог стволов свежий (камбий не поврежден)",
      "322  Ожог стволов свежий (камбий поврежден <3/4 окружности ствола)",
      "323  Ожог стволов свежий (камбий поврежден >3/4 окружности ствола)",
      "324  Ожог стволов 1-3 лет (камбий не поврежден)",
      "325  Ожог стволов 1-3 лет (камбий поврежден <3/4 окружности ствола)",
      "326  Ожог стволов 1-3 лет (камбий поврежден >3/4 окружности ствола)",
      "327  Ожог стволов пр. лет (камбий не поврежден)",
      "328  Ожог стволов прошлых лет (камбий поврежден <3/4 окружности ствола)",
      "329  Ожог стволов прошлых лет (камбий поврежден >3/4 окружности ствола)",
      "331  Ожог кроны свежий (<3/4 кроны)",
      "332  Ожог кроны свежий (>3/4 кроны)",
      "333  Ожог кроны прошлых лет (<3/4 кроны)",
      "334  Ожог кроны прошлых лет (>3/4 кроны)"
    ]
  },

  {
    group: "Повреждение хвои – листвы",
    items: [
      "401  Объедание листьев, хвои свежее (<25%)",
      "402  Объедание листьев, хвои свежее (25-50%)",
      "403  Объедание листьев, хвои свежее (51-75%)",
      "404  Объедание листьев, хвои свежее (>75%)",
      "405  Объедание хвои прошлых лет (<25%)",
      "406  Объедание хвои прошлых лет (25-50%)",
      "407  Объедание хвои прошлых лет (51-75%)",
      "408  Объедание хвои прошлых лет (>75%)",
      "409  Скелетирование листьев (<25%)",
      "410  Скелетирование листьев (25-50%)",
      "411  Скелетирование листьев (51-75%)",
      "412  Скелетирование листьев (>75%)",
      "413  Минирование листьев, хвои (<25%)",
      "414  Минирование листьев, хвои (25-50%)",
      "415  Минирование листьев, хвои (51-75%)",
      "416  Минирование листьев, хвои (>75%)",
      "417  Галлообразование (<25%)",
      "418  Галлообразование (25-50%)",
      "419  Галлообразование (51-75%)",
      "420  Галлообразование (>75%)"
    ]
  },

  {
    group: "Дехромация",
    items: [
      "501  Дехромация хвои, листвы (патологическая) (<25%)",
      "502  Дехромация хвои, листвы (патологическая) (25-50%)",
      "503  Дехромация хвои, листвы (патологическая) (51-75%)",
      "504  Дехромация хвои, листвы (патологическая) (>75%)"
    ]
  },

  {
    group: "Повреждение насекомыми",
    items: [
      "601  Повреждение ветвей стволовыми при дополнительном питании свежее (<25% ветвей)",
      "602  Повреждение ветвей стволовыми при дополнительном питании свежее (25-50% ветвей)",
      "603  Повреждение ветвей стволовыми при дополнительном питании свежее (51-75% ветвей)",
      "604  Повреждение ветвей стволовыми при дополнительном питании свежее (>75% ветвей)",
      "605  Повреждение ветвей стволовыми при дополнительном питании прошлых лет (<25% ветвей)",
      "606  Повреждение ветвей стволовыми при дополнительном питании прошлых лет (25-50% ветвей)",
      "607  Повреждение ветвей стволовыми при дополнительном питании прошлых лет (51-75% ветвей)",
      "608  Повреждение ветвей стволовыми при дополнительном питании прошлых лет (>75% ветвей)",
      "609  Местное поселение стволовых вредителей (заселено)",
      "610  Местное поселение стволовых вредителей (отработано)",
      "631  Повреждение корней корнегрызами свежее",
      "632  Повреждение корней корнегрызами прошлых лет",
      "651  Повреждение шишек, семян, плодов (<30%)",
      "652  Повреждение шишек, семян, плодов (31-60%)",
      "653  Повреждение шишек, семян, плодов (>60%)"
    ]
  },

  {
    group: "Грибные и бактериальные болезни",
    items: [
      "801  Наличие плодовых тел на стволе",
      "802  Наличие плодовых тел в комлевой части и на корнях",
      "803  Наличие на стволе в обл.кроны раковых ран, язв, образований (<1/2 окружности ствола)",
      "804  Наличие на стволе в обл.кроны раковых ран, язв, образований (1/2-2/3 окружности ствола)",
      "805  Наличие на стволе в обл.кроны раковых ран, язв, образований (>2/3 окружности ствола)",
      "806  Наличие на стволе под кроной раковых ран, язв, образований (<1/2 окружности ствола)",
      "807  Наличие на стволе под кроной раковых ран, язв, образований (1/2-2/3 окружности ствола)",
      "808  Наличие на стволе под кроной раковых ран, язв, образований (>2/3 окружности ствола)",
      "820  Смолотечение",
      "821  Сокотечение",
      "831  Наличие дупел, табачных сучков",
      "832  Наличие гнили",
      "833  Наличие синевы древесины",
      "851  Наличие органов плодоношения на ветвях",
      "852  Наличие органов плодоношения на листве (хвое)",
      "853  Грибница, ризоморфы и мицелиальные плёнки под корой"
    ]
  }
];
